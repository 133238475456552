import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { addCalibrationHistoryRecord } from "../../../../../scripts/calibrationHistory";
import { subscriptionsTypes } from "../../../../../scripts/subscription/subscriptionTypes";
import { postUserInteraction } from "../../../../../scripts/userInteraction";
import { getWavedVolume } from "../../../../../scripts/volume";
import { updateZoneCalibration } from "../../../../../scripts/zone";
import { updateZoneModes } from "../../../../../scripts/zoneModes";
import theme from "../../../../../UI/theme";
import { userInteractionTypes } from "../../../../admin/customerDetails/userInteractionTypes";
import Button from "../../../../UiComponents/Button";
import ProgressBar from "../../../../UiComponents/ProgressBar";
import ViewHeader from "../../../../UiComponents/ViewHeader"
import { SettingsParagraph, SettingsSubHeader } from "../../../settingsStyles";
import { ButtonContainer, CalibrationMetric, ColumnContainer } from "../calibrationStyles";
import LiveCalibrationRules from "./LiveCalibrationRules";
import LiveCalibrationSlider from "./LiveCalibrationSlider";
import { ErrorLabel, LiveCalibrationContainer, PointContainer } from "./liveCalibrationStyles";

const calibrationStates = {
    NONE: 'NONE',
    CALIBRATING: 'CALIBRATING',
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED'
}

const MINIMUM_NO_OF_VALID_LOGS = 4;
const MAXIMUM_STANDARD_DEVIATION = 4;

const ZoneLiveCalibrationView = props => {
    const { zoneId } = useParams();
    const [zone, setZone] = useSubscription(subscriptionsTypes.zone, zoneId);
    const [zoneModes, setZoneModes] = useSubscription(subscriptionsTypes.zoneModes);
    const [zoneLive, setZoneLive] = useSubscription(subscriptionsTypes.zoneLive, zoneId);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [hubs, setHubs] = useSubscription(subscriptionsTypes.hubs);
    const [calibrationState, setCalibrationState] = useState(calibrationStates.NONE)
    const [calibrationZoneLives, setCalibrationZoneLives] = useState([]);
    const [calibrationProgress, setCalibrationProgress] = useState(0);
    const [newCalibrationPoints, setNewCalibrationPoints] = useState();
    const [errorText, setErrorText] = useState('');
    const [isCalibrating, setIsCalibrating] = useState(false);
    const [livePoint, setLivePoint] = useState();

    useEffect(() => {
        if (calibrationState === calibrationStates.CALIBRATING) {
            setCalibrationZoneLives(prev => [...prev, { ...zoneLive }]);
        }
    }, [zoneLive?.time])

    useEffect(() => {
        let result = getValidLogs();
        setCalibrationProgress(prev => Math.max((result?.length / MINIMUM_NO_OF_VALID_LOGS) * 100, prev));
        if (result.length >= MINIMUM_NO_OF_VALID_LOGS) {
            setCalibrationState(calibrationStates.COMPLETE);
            const newPoints = computeNewCalibrationPoints(result);
            setTimeout(() => {
                validateNewCalibrationPoints(newPoints);
                setIsCalibrating(false);
            }, 500);
        }
    }, [calibrationZoneLives])

    const startLiveCalibration = () => {
        setCalibrationState(calibrationStates.CALIBRATING);
        setCalibrationZoneLives([]);
        setIsCalibrating(true);
        setCalibrationProgress(0);
        setErrorText('');
        setTimeout(() => {
            if (calibrationState === calibrationStates.CALIBRATING) {
                let result = getValidLogs();
                if (result.length < MINIMUM_NO_OF_VALID_LOGS) {
                    setCalibrationState(calibrationStates.FAILED);
                    setIsCalibrating(false);
                    setErrorText('Not enough valid measurements. Please try again.');
                } else {
                    setCalibrationState(calibrationStates.COMPLETE);
                }
            }
        }, 60000);
    }

    const computeNewCalibrationPoints = (logs) => {
        try {
            const pLive_dB = Math.max(logs?.reduce((a, b) => a + b?.averageDecibel - b?.averageDiff, 0) / logs?.length, zone?.calibrationPoints?.points?.[0]?.measuredDb);
            const pLive_vol = logs?.[0]?.sysvolAbsolute;

            const pLive = { sysvol: pLive_vol, measuredDb: pLive_dB };
            setLivePoint(pLive);
            const pLow = zone?.calibrationPoints?.points?.[0];
            const pMid = zone?.calibrationPoints?.points?.[1];
            const pHigh = zone?.calibrationPoints?.points?.[2];

            const c = (pMid.sysvol - pLow.sysvol) / (pHigh.sysvol - pLow.sysvol);
            const w = (pLive.measuredDb - pLow.measuredDb) / (pMid.measuredDb - pLow.measuredDb);

            let newPLow = { ...pLow };
            let newPMid = { ...pMid };
            let newPHigh = { ...pHigh };
            if (w < 1 && w > 0) {
                newPLow.sysvol = (pHigh.sysvol * c - (pLive.sysvol / w) - (pLow.sysvol * w * c / (1 - w))) / (c - (1 / w) - (w * c / (1 - w)));
                newPMid.sysvol = newPLow.sysvol + (pLive.sysvol - newPLow.sysvol) / w;
                newPHigh.sysvol = pHigh.sysvol + (w * (newPLow.sysvol - pLow.sysvol) / (1 - w));
            } else if (w >= 1) {
                const r = (pHigh.measuredDb - pMid.measuredDb) / (pLive.measuredDb - pMid.measuredDb);
                newPMid.sysvol = (pLow.sysvol * (1 - (1 / c)) - pLive.sysvol * r) / (1 - (1 / c) - r);
                newPHigh.sysvol = newPLow.sysvol + (newPMid.sysvol - newPLow.sysvol) / c;
            } else if (w <= 0) {
                newPLow.sysvol = pLive.sysvol;
                newPMid.sysvol = newPLow.sysvol + c * (newPHigh.sysvol - newPLow.sysvol);
            }

            newPLow.sysvol = Math.round(newPLow.sysvol);
            newPMid.sysvol = Math.round(newPMid.sysvol * 2) / 2;
            newPHigh.sysvol = Math.round(newPHigh.sysvol);

            const newPoints = [newPLow, newPMid, newPHigh];
            setNewCalibrationPoints(newPoints);
            return newPoints;
        } catch (error) {
            setCalibrationState(calibrationStates.FAILED);
            setIsCalibrating(false);
            setErrorText('Unexpected error. Try again.');
        }
        return null;
    }

    const getValidLogs = () => {
        let logsCopy = JSON.parse(JSON.stringify(calibrationZoneLives))?.slice(Math.max(calibrationZoneLives?.length - MINIMUM_NO_OF_VALID_LOGS, 0));

        let validLogs = [];
        let lastSysvol = logsCopy?.[logsCopy?.length - 1]?.sysvol;

        for (let index = logsCopy?.length - 1; index >= 0; index--) {
            const log = logsCopy?.[index];

            let lastLogs = logsCopy?.slice(index);

            const meanFilteredDb = lastLogs?.reduce((a, b) => a + b?.averageDecibel - b?.averageDiff, 0) / lastLogs?.length;
            const standardDeviation = Math.sqrt(lastLogs?.map(log => Math.pow((log?.averageDecibel - log?.averageDiff) - meanFilteredDb, 2))?.reduce((a, b) => a + b, 0) / lastLogs?.length);

            if (
                !log?.averageDecibel ||
                !log?.averageDiff ||
                !log?.sysvolAbsolute ||
                log?.sysvol !== lastSysvol ||
                log?.isRegulating === 1 ||
                log?.isRegulatingAudioSource === 0 ||
                standardDeviation > MAXIMUM_STANDARD_DEVIATION) {
                break;
            } else {
                validLogs.unshift(log);
            }
        }

        return validLogs;
    }

    const validateNewCalibrationPoints = (newPoints) => {
        for (let index = 0; index < newPoints?.length; index++) {
            const element = newPoints?.[index];
            if (element?.sysvol > 100 || element?.sysvol < 0) {
                setCalibrationState(calibrationStates.FAILED);
                setErrorText("Invalid calibration points. Points outside valid range.");
            } else if (index > 0 && element?.sysvol < newPoints?.[index - 1]?.sysvol + 0.5) {
                setCalibrationState(calibrationStates.FAILED);
                setErrorText("Invalid calibration points. Points should be strictly increasing.");
            } else if (isNaN(element?.sysvol)) {
                setCalibrationState(calibrationStates.FAILED);
                setErrorText("Invalid calibration points. Points must be numeric.");
            }
        }
    }

    const handleReset = () => {
        setCalibrationState(calibrationStates.NONE);
        setCalibrationZoneLives([]);
        setCalibrationProgress(0);
        setIsCalibrating(false);
        setNewCalibrationPoints(null);
        setErrorText('');
        setLivePoint(null);
    }

    const handleSave = async () => {
        const oldCalibrationPoints = JSON.parse((JSON.stringify(zone?.calibrationPoints)));

        let updatedZoneModes = JSON.parse(JSON.stringify(zoneModes?.filter(zm => zm.zoneId === zoneId)?.sort((a, b) => a.orderIndex - b.orderIndex)));

        if (livePoint?.sysvol > newCalibrationPoints?.[2]?.sysvol) {
            const newMaxVolume = getWavedVolume(livePoint?.sysvol, newCalibrationPoints?.map(point => point.sysvol), 1);
            const difference = Math.round(newMaxVolume - updatedZoneModes?.find(zm => zm.orderIndex === 0)?.maxVol);

            if (difference > 0) {
                for (let index = updatedZoneModes?.length - 1; index >= 0; index--) {
                    const zoneMode = updatedZoneModes[index];
                    zoneMode.maxVol = Math.min(100, zoneMode?.maxVol + difference);
                    if (index < updatedZoneModes?.length - 1) {
                        zoneMode.maxVol = Math.min(zoneMode?.maxVol, updatedZoneModes?.[index + 1]?.maxVol - 1);
                    }
                }

                await updateZoneModes(updatedZoneModes, zone?.customerId);
            }
        }

        const baseZoneMode = updatedZoneModes?.find(zm => zm.orderIndex === 0);

        const result = await updateZoneCalibration({
            zoneId: zone?.zoneId,
            calibrationPoints: { points: newCalibrationPoints, calibrationId: zone?.calibrationPoints?.calibrationId },
            minVol: baseZoneMode?.minVol,
            maxVol: baseZoneMode?.maxVol,
            delta: baseZoneMode?.delta,
            zoneModeOrderIndex: baseZoneMode?.orderIndex
        });

        if (result) {
            await addCalibrationHistoryRecord(zone?.customerId, {
                zoneId: zone?.zoneId,
                customerId: zone?.customerId,
                calibrationPoints: newCalibrationPoints
            });
            await postUserInteraction(zone?.customerId, {
                zoneId: zone?.zoneId,
                settings: userInteractionTypes.calibrate.key,
                payload: {
                    from: {
                        calibrationPoints: oldCalibrationPoints
                    },
                    to: {
                        calibrationPoints: newCalibrationPoints,
                        calibrationId: zone?.calibrationPoints?.calibrationId
                    }
                }
            })
            handleReset();
        }
    }

    return <>
        <ViewHeader headerText={`${zone?.zoneName} - Live Calibration`} backLink={'/settings/livecalibration'} showVenueName={true} />

        <ColumnContainer>
            <LiveCalibrationContainer>
                <ColumnContainer>
                    <SettingsSubHeader>Requirements</SettingsSubHeader>

                    <LiveCalibrationRules
                        zone={zone}
                        sourceSelector={sourceSelectors?.find(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId)}
                        sources={sources?.filter(source => source?.processorId === zone?.processorId)}
                        hub={hubs?.find(hub => hub.hubId === zone?.hubId)}
                        processor={processors?.find(processor => processor.processorId === zone?.processorId)}
                    />
                </ColumnContainer>
            </LiveCalibrationContainer>

            <LiveCalibrationContainer>
                <ColumnContainer>
                    <SettingsSubHeader>Calibrate volume</SettingsSubHeader>

                    {calibrationState === calibrationStates.NONE ? <>
                        <SettingsParagraph>Adjust the music volume so that the music perfectly matches the ambience.</SettingsParagraph>

                        <LiveCalibrationSlider
                            zone={zone}
                            zoneLive={zoneLive}
                            sourceSelector={sourceSelectors?.find(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId)}
                            sources={sources?.filter(source => source?.processorId === zone?.processorId)}
                        />

                        <ButtonContainer>
                            <Button onClick={() => startLiveCalibration()} primary>Calibrate</Button>
                        </ButtonContainer>

                    </> : <></>}

                    {calibrationState === calibrationStates.CALIBRATING || isCalibrating === true ? <>
                        <SettingsParagraph>Calibrating...</SettingsParagraph>
                        <ProgressBar progress={calibrationProgress} />
                        <ButtonContainer>
                            <Button secondary onClick={() => handleReset()}>Cancel</Button>
                        </ButtonContainer>
                    </>
                        : <></>}

                    {calibrationState === calibrationStates.FAILED ? <>
                        <ErrorLabel>{errorText}</ErrorLabel>
                        <ButtonContainer>
                            <Button primary onClick={() => handleReset()}>Retry</Button>
                        </ButtonContainer>
                    </>
                        : <></>}

                    {calibrationState === calibrationStates.COMPLETE && isCalibrating === false ? <>
                        <PointContainer>
                            <SettingsParagraph>Old calibration</SettingsParagraph>
                            {zone?.calibrationPoints?.points?.map((point, index) => {
                                let header = index === 0 ? 'Low' : index === 1 ? 'Med' : 'High';
                                return <CalibrationMetric color={theme.colors.yellowFever} key={index}>
                                    <label>{header}</label>
                                    <label>{point.sysvol}</label>
                                </CalibrationMetric>
                            })}
                        </PointContainer>

                        <PointContainer>
                            <SettingsParagraph>New calibration</SettingsParagraph>
                            {newCalibrationPoints?.map((point, index) => {
                                let header = index === 0 ? 'Low' : index === 1 ? 'Med' : 'High';
                                return <CalibrationMetric color={theme.colors.yellowFever} key={index}>
                                    <label>{header}</label>
                                    <label>{point?.sysvol}</label>
                                </CalibrationMetric>
                            })}
                        </PointContainer>

                        <ButtonContainer>
                            <Button primary onClick={() => handleSave()}>Save</Button>
                            <Button secondary onClick={() => handleReset()}>Discard</Button>
                        </ButtonContainer>
                    </> : <></>}

                </ColumnContainer>
            </LiveCalibrationContainer>
        </ColumnContainer>
    </>
}

export default ZoneLiveCalibrationView;

